<!-- 解决方案 -->
<template>
  <div class="wrapper pageBox">
    <!-- banner -->
    <solutionBanner id="solutionBanner" ref="solutionBanner" :from="'yljk'" />
    <!-- 二级导航 -->
    <navBar ref="navBar" :from="'yljk'" />
    <!-- 行业分析 -->
    <div id="hyfx_div" class="fenxi-box">
      <h1 class="h1-title">行业分析</h1>
      <div class="fenxi conBox flex-between flex-align">
        <div class="imgs">
          <img class="img" src="@/assets/solution/cyyq/fenxi_yljk_bg.png">
        </div>
        <div>
          <div class="text">我们认为目前病房存在的问题包括病区数据采集智能化程度低、系统之间缺乏联系形成信息孤岛、缺乏面向患者的个性化服务、缺乏系统信息数据的二次分析途径和手段、缺乏智能化病房数据及分析数据支撑等。基于这些现状，以及以卫健委为核心的领导思想，我们认为行业的发展将从以下方面入手：</div>
          <div class="text" style="margin-top:15px">1. 明确医院智慧管理各级别实现的功能，为医院加强智慧管理相关工作提供参照，如开展多种医疗健康场景下的智能语音技术应用，开展基于人工智能技术、医疗健康智慧感知终端设备的移动医疗示范</div>
          <div class="text" style="margin-top:15px">2. 以病房为单元，开展移动护理、生命体征在线监测等服务，作为北向衔接HIS和南向对接以智能医疗器械为代表的物联设备；</div>
          <div class="text" style="margin-top:15px">3. 完善“三位一体”智慧医院建设的顶层设计，使之成为提升医院现代化管理水平的有效工具。以三级医院为先锋，进行实现院内服务信息互通共享的数字化改革尝试。</div>
        </div>
      </div>
    </div>
    <!-- 解决方案能力全景 -->
    <div id="faqj_div" class="conBox nlfa">
      <h1 class="h1-title">解决方案能力全景</h1>
      <img class="img" src="@/assets/solution/cyyq/nlfa_yljk.png">
      <img class="img1" src="@/assets/solution/cyyq/jjfabg.png">
    </div>
    <!-- 平台典型功能介绍 -->
    <carouselBanner id="ptgn_div" ref="carouselBanner" :from="'yljk'" />
    <!-- 适用场景 -->
    <div id="sycj_div" class="sycj-div">
      <h1 class="h1-title">适用场景</h1>
      <sycj ref="sycj" class="conBox" :from="'yljk'" />
    </div>
    <!-- 数字嘉园 美好生活 -->
    <footers ref="footers" />
  </div>
</template>

<script>
import solutionBanner from './components/solutionBanner.vue';
import sycj from './components/sycj.vue';
import footers from './components/footer.vue';
import carouselBanner from './components/carouselBanner.vue';
import navBar from './components/navBar.vue';
export default {
  name: 'Solution',
  components: { solutionBanner, sycj, footers, carouselBanner, navBar },
  data() {
    return {
    };
  },
  watch: {
  },
  mounted() {
  },
  methods: {
  }
};
</script>

<style lang="scss" scoped>

.flex-start {
  display: flex;
  justify-content: start;
}
.flex-between {
  display: flex;
  justify-content: space-between;
}
.flex-align {
  align-items: center;
}
.conBox {
  width: 1200px;
  margin: 0 auto;
}
.fenxi-box {
  padding-bottom: 80px;
  background: #F9FBFF;
  .fenxi {
    .imgs,.img{
      width: 594px;
      height: 433px;
    }
    .text {
      font-size: 14px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      text-align: left;
      color: #666666;
      line-height: 30px;
      padding-left: 50px;
      text-align: justify;
    }
  }
}
.nlfa {
  position: relative;
  text-align: center;
  margin-bottom: 80px;
  .img {
    width: 1200px;
    height: 664px;
    margin: auto;
  }
  .img1 {
    position: absolute;
    width: 600px;
    height: 500px;
    bottom: 4%;
    right: -22%;
  }
}
</style>

